/* About*/

.left-about {
    padding-top: 20px;
    text-align: left;
    color: #fff;
    font-family: futura-pt, sans-serif;
    font-size: 20px;
  }

  #border-top {
    border-top: 1px solid #fff;
    padding-bottom: 20px;
    margin-top: 70px;
}
  
  h1.left-about {
    padding-bottom: 20px;
    margin-bottom: 70px;
    
  }
#border-bottom {
    border-bottom: 0.5px solid #fff;
    margin-bottom: 50px;
}

.aboutspacing {
    padding-bottom: 50px;
    font-size: 20px;
} 

.p25 {
    padding-bottom: 25px;
}

@media only screen and (max-width: 480px) { 
.about {
    margin-top: 80px;
}

.m-100 {
    width: 90vw !important;
}

}